.new-expence_controls {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    text-align: left;
}

.new-expence_controls label {
    font-weight: bold;
    margin-bottom : 0.5rem;
    display: block;

}

.new-expence_controls input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 300px;
    max-width: 100%;
}

.new-expence_actions {
text-align: right;
}