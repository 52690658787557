.expencedate{
   display: flex;
   flex-direction: column;
   width: 5.5rem;
   height: 5.5rem;
   border: 1px solid #ececec;
   background-color: #2a2a2a;
   color: white;
   border-radius: 12px;
   align-items: center;
   justify-content: center;
}
.expencedate_month{
    font-size: 0.75rem;
    font-weight: bold;

}
.expencedate_year{
  font-size: 0.75rem;

}
.expencedate_day{
   font-size: 1.5rem;
   font-weight: bold;
}