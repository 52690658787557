body{
  margin: 0;
  background-color: grey;
}

*{
  box-sizing: border-box;
}


html{
  font-family: 'noto sans JP', sans-serif, sans-serif;
} 

