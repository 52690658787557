.ExpenceItem{
    background-color: #34495e;
    border-radius: 12px;
    margin: 1rem 0;
    padding: 0.5rem;
    box-shadow:0 2px 8px rgba(0, 0, 0, 0.25) ;
    align-items: center;
    justify-content: space-between;
    display: flex;


}

.Expencedes{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: row;
    justify-content: space-between;
    flex: 1;

}

.Expencecost{
    font-size: 1rem;
    font-weight : bold;
    color: white;
    background-color: #30336b;
    border: 1px solid white;
    padding: 0.5 rem;
    border-radius: 12px;

} 

.ExpenceItem h2{
    color: #3a3a3a;
    font-size:  20px;
    float: left;
    margin: 0 1rem;
    color: white;
}

@media (min-width : 580px){
    .Expencedec{
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }

    .Expencedec h2 {
        font-size: 1.25rem;

    }
    .Expencecost{
        font-size:1.25rem;
        padding: 0.5rem 1.5rem;
    }
}